export enum OptimizelyExperiments {
  paymentsFlightsMerchantFees = 'payments_flight_merchant_fees',
  tripExplore = 'trip_explore',
  cruiseNewBookingFlow = 'cruise_new_cruisebank_booking_flow_may_2024',
  flightPreFillRecentSearch = 'flights_pre_fill_recent_search',
  isSupportAssistantEnabled = 'opex_support_assistant_customer_pool',
  paymentsHotelsDepositsAu = 'payments_hotels_deposits_au_v2',
  paymentsHotelsDepositsNonAu = 'payments_hotels_deposits_non_au',
  pricePerNight = 'cro_price_per_night_non_au_nz',
  lereSearchModelV2 = 'lere_search_model_v2',
  searchAltAvailabilityV2 = 'search_alt_dates_availability_v2',
  searchWeightedRecencyEv = 'search_recency_weighted_ev',
  bedbankChangeDatesEnabled = 'opex_bedbank_change_dates',
  searchClusterMapPins = 'search_cluster_map_pins',
  supportAssistantWidgetEnabled = 'opex_lesa_widget',
  expediaStyleLoginPrompt = 'expedia_style_login_prompt',
  luxPlusImprovedUpsellLowSavings = 'lux_plus_improved_upsell_ui_low_member_savings',
  luxPlusImprovedUpsellHighSavings = 'lux_plus_improved_upsell_ui_high_member_savings',
  emailChangeEnabled = 'opex_email_change',
  romanticLandingPage = 'romantic_properties_v1_thematic_landing_page_a_b_test',
  ghaLandingPageEnabled = 'gha_landing_page',
  signUpModalTitleEnabled = 'cro_sign_up_modal_without_save_up_to_70',
  searchLowMpvHiding = 'search_low_mpv_hiding',
  searchHotelPriceFiltering = 'search_hotel_price_filtering',
  walledGardenEuropeOffers = 'cro_walled_garden_europe_offers',
  appsflyerVariantsEnabled = 'cro_appsflyer_banner_variants',
  refundProtectForInternationalEnabled = 'cro_refund_protect_vs_insurance_international',
  croRefundProtectSideBySide = 'cro_refund_protect_side_by_side_with_yes_and_no_options',
  croBedbankOptionSortOrder = 'cro_bedbank_option_sort_order',
}

export enum OptimizelyFeatureFlags {
  luxLoyaltyFeatureToggle = 'lux_loyalty_feature_toggle',
  luxPlusFeatureToggle = 'luxplus_feature_toggle',
  luxPlusToursToggle = 'luxplus_tours_toggle',
  luxPlusDiscountedInsuranceBenefit = 'lux_plus_discounted_insurance_benefit',
  luxPlusDiscountedBookingProtectionBenefit = 'lux_plus_discounted_booking_protection_benefit',
  isGoCardlessEnabled = 'payments_go_cardless',
  allowPhoneNumberLogin = 'allow_phone_number_login',
  promoAppBanner = 'app_banner_targeted_delivery',
  CSAgentsAutoQuote = 'cro_cs_agents_auto_quote',
  tourOptionalExtras = 'tours_optional_extras',
  tourReviewsEnabled = 'cro_tour_reviews',
  toursRequestAgentCallbackEnabled = 'csx_tours_request_agent_callback',
  hotelsAndVillasRequestAgentCallbackEnabled = 'csx_hotels_and_villas_request_agent_callback',
  cancellationOutsidePolicyEnabled = 'opex_cancellation_outside_refund_policy',
  tourOptionalExtraPostPurchase = 'tour_optional_extra_post_purchase',
  searchStreaming = 'search_streaming',
  searchPricing = 'search_pricing',
  isCruiseMultiCabinBookingEnabled = 'cruise_multi_cabin_booking',
  rpForExperienceEnabled = 'cro_rp_for_experience',
  searchNewHotelIndex = 'search_new_hotel_index',
  notificationCentreEnabled = 'cro_notification_centre',
  flashExtendDatesEnabled = 'opex_flash_extend_dates',
  croNewFlightsSaleCarouselEnabled = 'cro_flights_sale_carousel',
}
